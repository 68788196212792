import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleAnalyticsService } from './core/common/service/google-analytics/google-analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private router: Router,
    private gaService: GoogleAnalyticsService,
    private titleService: Title,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gaService.logPageView(event.urlAfterRedirects, titleService.getTitle());
      }
    });
  }
}
