import pkg from 'package.json';

export const environment = {
  production: true,
  release: pkg.version,
  endpoint: 'https://admin-api.xprivate.education/xprivate',
  // endpoint: 'https://content.xprivate.education/v1',
  token:
    'fe51d8d21567f306c78e041df2ca120fe28e0c351a141e846694fbbb3dfe55068199a3d69c6f49b7b49d7cb60bc179f19ee10b40004c06aad2dbb101992beba177d1a8383fcef81a0acffc0b551c4a5a069ccbb0e3ef1b2dd4ccda0b74c916ede6b632555561c0fe1cbb72c58db7e77922b5438b4a9e301b4720b95ca323dceb',
};
