import { Injectable } from '@angular/core';

@Injectable()
export class GoogleAnalyticsService {
  constructor() {}

  logPageView(pagePath: string, pageTitle?: string): void {
    gtag('event', 'page_view', {
      page_path: pagePath,
      page_title: pageTitle,
    });
  }

  logSignUp(method: string): void {
    gtag('event', 'sign_up', {
      method,
    });
  }

  logPartnerNavigation(partnerName: string): void {
    gtag('event', 'partner_navigation', {
      partner_name: partnerName,
    });
  }

  logFooterSocialMedia(socialMediaName: string): void {
    gtag('event', 'footer_social_media', {
      social_media_name: socialMediaName,
    });
  }

  logException(description: string, fatal: boolean = false): void {
    gtag('event', 'exception', { description, fatal });
  }
}
